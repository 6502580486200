// src/components/HomePage.js
import React from 'react';

function Services() {

  return (
    <div>
      <h1>Welcome</h1>
      <div>Services</div>
    </div>
  );
}

export default Services;
