// src/components/HomePage.js
import React from 'react';

function About() {

  return (
    <div>
      <h1>About</h1>
      <div>
        <p>This is the About page.</p>
      </div>
    </div>
  );
}

export default About;
