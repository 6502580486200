// src/components/HomePage.js
import React from 'react';

function Contact() {

  return (
    <div>
      <h1>Contact</h1>
      <div>
        <p>This is the Contact page.</p>
      </div>
    </div>
  );
}

export default Contact;
