// src/components/HomePage.js
import React from 'react';

function Blog() {


  return (
    <div>
      <h1>Welcome to my BLOG</h1>
      <div>Blog</div>
    </div>
  );
}

export default Blog;
