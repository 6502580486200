// src/components/HomePage.js
import React from 'react';

function Write() {
  
  return (
    <div>
      <h1>Welcome</h1>
      <div>WRite</div>
    </div>
  );
}

export default Write;
